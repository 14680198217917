import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const query = graphql`
  query JobsPageQuery {
    strapiJobsPage {
      title
      slug
      description
      content
    }
    allStrapiJobs(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          slug
          order
        }
      }
    }
  }
`

const JobsPage = ({ data }) => {
  const jobsPage = data.strapiJobsPage

  function markupJobsPageContent() {
    return { __html: jobsPage.content }
  }

  return (
    <Layout>
      <Seo
        title={jobsPage.title + " | Vacational Studies"}
        description={jobsPage.description}
      />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Submenu</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  {data.allStrapiJobs.edges.map((jobs, i) => {
                    return (
                      <li
                        className="subnav__item"
                        key={`page__${jobs.node.slug}`}
                      >
                        <Link
                          className="subnav__link"
                          to={`/jobs/${jobs.node.slug}`}
                        >
                          {jobs.node.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div
        id="content"
        className="container max-width-adaptive-sm padding-top-xl padding-bottom-xxl position-relative z-index-1"
      >
        <article className="article text-component">
          <h1 className="text-xxxl">{jobsPage.title}</h1>
          <p className="text-md color-contrast-medium">
            {jobsPage.description}
          </p>
          <div dangerouslySetInnerHTML={markupJobsPageContent()} />
          <Link
            className="color-inherit text-decoration-none btn btn--primary"
            to={`/jobs/application`}
          >
            Apply for a Job
          </Link>
        </article>
      </div>
    </Layout>
  )
}

export default JobsPage
